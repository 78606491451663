import React from "react";
import { Link as GatsbyLink } from "gatsby";

import { isUrlAbsolute } from "../../lib/utils";

interface LinkProps {
  to: string;
  children: React.ReactNode;
  target?: string;
  className?: string;
  activeClassName?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Link = ({
  to,
  children,
  target,
  className,
  activeClassName,
  onClick,
}: LinkProps) => {
  return isUrlAbsolute(to) ? (
    <a
      href={to}
      target={target}
      className={className}
      onClick={onClick}
      {...(target === "_blank" ? { rel: "noopener" } : {})}
    >
      {children}
    </a>
  ) : (
    <GatsbyLink
      to={to}
      className={className}
      onClick={onClick}
      activeClassName={activeClassName}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
