export enum MediaType {
  Image,
  Video,
  Audio,
}

export interface MediaData {
  title?: string;
  filename?: string;
  mime?: string;
  src: string;
  abstract?: string;
}
